<template>
  <in-layout>
    <template slot="list">
      <v-container grid-list-md class="px-4">

        <v-layout column class="ma-2">
          <div class="mb-4">
            <router-link style="cursor: pointer" tag="span" :to="{ name: 'Empresas'}">
              <v-icon size="18">mdi-chevron-left</v-icon>
              Voltar para empresas cadastradas
            </router-link>
          </div>

          <h1>Dados da empresa</h1>
        </v-layout>

        <v-flex class="mx-2 my-4" xs12>
          <v-card>
            <v-card-text class="pa-3">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-layout wrap>
                  <v-flex md7>
                    <v-text-field
                      label="Nome"
                      v-model="entity.name"
                      :rules="[validationRules.required]"
                      required
                    />

                    <v-layout row>
                      <v-flex xs6>
                        <v-text-field
                          v-model="entity.cpf_cnpj"
                          label="CNPJ ou CPF"
                          :mask="getCpfCnpjMask(entity.cpf_cnpj)"
                          disabled
                        />
                      </v-flex>
                      <v-flex xs6 class="ml-3">
                        <v-text-field
                          v-model.trim="entity.cellphone"
                          mask="(##) # ####-####"
                          label="Celular"
                          required
                          :rules="[validationRules.cellphone]"
                        />
                      </v-flex>
                    </v-layout>

                    <v-text-field
                      v-model="entity.address.zip_code"
                      append-icon="mdi-magnify"
                      label="CEP"
                      :mask="masks.postalcode"
                      :rules="[validationRules.required, validationRules.postalcode]"
                      @keyup.enter="searchAddressByCEP"
                      @blur="searchAddressByCEP"
                    />

                    <v-layout row>
                      <v-flex xs10>
                        <v-text-field
                          v-model="entity.address.street"
                          label="Logradouro"
                          :rules="[validationRules.required]"
                          :loading="isFetchingAddress"
                          :readonly="isFetchingAddress"
                        />
                      </v-flex>
                      <v-flex xs2 class="ml-3">
                        <v-text-field
                          v-model="entity.address.number"
                          :rules="[validationRules.required]"
                          label="Número"
                          :loading="isFetchingAddress"
                          :readonly="isFetchingAddress"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout row>
                      <v-flex xs3>
                        <v-text-field
                          v-model="entity.address.neighborhood"
                          label="Bairro"
                          :rules="[validationRules.required]"
                          :loading="isFetchingAddress"
                          :readonly="isFetchingAddress"
                        />
                      </v-flex>
                      <v-flex xs9 class="ml-3">
                        <v-text-field
                          v-model="entity.address.complement"
                          label="Complemento"
                          :loading="isFetchingAddress"
                          :readonly="isFetchingAddress"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout row>
                      <v-flex xs3>
                        <v-autocomplete
                          v-model="entity.state"
                          :items="federalUnits"
                          @change="fetchCities()"
                          :rules="[validationRules.required]"
                          :loading="isFetchingAddress"
                          :readonly="isFetchingAddress"
                          label="Estado"
                          no-data-text="Nenhum resultado encontrado"
                          item-text="acronym"
                          item-value="id"
                          return-object
                          single-line
                        />
                      </v-flex>
                      <v-flex xs9 class="ml-3">
                        <v-autocomplete
                          v-model="entity.city"
                          :items="cities"
                          :rules="[validationRules.required]"
                          :loading="isFetchingAddress"
                          :readonly="isFetchingAddress"
                          label="Município"
                          no-data-text="Nenhum resultado encontrado"
                          item-text="name"
                          item-value="id"
                          return-object
                          single-line
                        />
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-spacer />

                  <v-flex md4>
                    <span class="subheading font-weight-bold">Perfil:</span>
                    <v-layout column class="">
                      <v-checkbox
                        color="primary"
                        v-model="entity.profile"
                        label="Gerador"
                        value="generator"
                      />
                      <v-checkbox
                        color="primary"
                        v-model="entity.profile"
                        label="Destinador"
                        value="receiver"
                      />
                      <v-checkbox
                        color="primary"
                        v-model="entity.profile"
                        label="Transportador"
                        value="transporter"
                      />
                      <v-checkbox
                        color="primary"
                        v-model="entity.profile"
                        label="Armazenador Temporário"
                        value="temporary_storage"
                      />
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-form>

            </v-card-text>
            <v-card-actions class="pa-3">
              <v-btn color="primary" :disabled="!valid" @click="save">Salvar alterações</v-btn>
              <v-btn flat @click="cancel">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-divider/>

        <employee-list :companyId="id" />
      </v-container>
    </template>
  </in-layout>
</template>

<script>
import InLayout from '@/layouts/InLayout.vue';
import PersonService from '@/services/person-service';
import getAddressByCEP from '@/utils/getAddressByCEP';
import validationRules from '@/mixins/validation-rules';
import federalUnitService from '@/services/federal-unit-service';
import EmployeeList from '@/components/person/employees/EmployeeList.vue';
import masks from '@/utils/masks';
import { mapActions } from 'vuex';
import { find, cloneDeep } from 'lodash';

export default {
  name: 'CompanyData',
  props: ['id'],
  components: {
    InLayout,
    EmployeeList,
  },
  async beforeMount() {
    this.federalUnits = await federalUnitService.fetchFederalUnits();
  },
  mounted() {
    this.getCompanyData();
  },
  data() {
    return {
      masks,
      validationRules,
      getAddressByCEP,
      entity: {
        address: {},
      },
      initialEntity: {},
      federalUnits: [],
      cities: [],
      isFetchingAddress: false,
      valid: false,
    };
  },
  methods: {
    ...mapActions({ toggleSnackbar: 'application/toggleSnackbar' }),
    getCompanyData() {
      this.isFetchingAddress = true;

      PersonService
        .show(this.id)
        .then(async (data) => {
          const state = find(this.federalUnits, { acronym: data.address.federal_unit.acronym });
          this.cities = await federalUnitService.fetchCities(state.id);

          this.initialEntity = {
            ...data,
            state,
            city: find(this.cities, { name: data.address.city.name }),
          };

          this.cloneEntity();
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.isFetchingAddress = false;
        });
    },
    cloneEntity() {
      this.entity = cloneDeep(this.initialEntity);
    },
    save() {
      const payload = {
        ...this.entity,
        address_attributes: {
          ...this.entity.address,
          federal_unit_id: this.entity.city.federal_unit_id,
          city_id: this.entity.city.id,
        },
      };

      this.isFetchingAddress = true;

      PersonService
        .updatePerson(this.id, payload)
        .then(() => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'success',
            message: 'Dados atualizados com sucesso!',
            messageIcon: 'mdi-check',
          });
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.response.data.join(', '),
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.isFetchingAddress = false;
        });
    },
    cancel() {
      this.cloneEntity();
    },
    getCpfCnpjMask(cpfCnpj = '') {
      return cpfCnpj.length < 12 ? '###.###.###-###' : '##.###.###/####-##';
    },
    async searchAddressByCEP() {
      if (this.entity.address && this.entity.address.zip_code.length < 8) return;
      this.isFetchingAddress = true;

      try {
        const address = await this.getAddressByCEP(this.entity.postalCode);
        const federalUnitForEntityAddress = find(this.federalUnits, { acronym: address.state });
        this.cities = await federalUnitService.fetchCities(federalUnitForEntityAddress.id);
        const cityForEntityAddress = find(this.cities, { name: address.city });

        const entityAddress = {
          ...address,
          state: federalUnitForEntityAddress,
          city: cityForEntityAddress,
        };

        Object.assign(this.entity, entityAddress);
      } catch (error) {
        this.toggleSnackbar({
          show: true,
          timeout: 6000,
          color: 'error',
          message: error.message,
          messageIcon: 'mdi-alert-outline',
        });
      } finally {
        this.isFetchingAddress = false;
      }
    },
    async fetchCities() {
      this.cities = await federalUnitService.fetchCities(this.entity.state.id);
    },
  },
};
</script>

<style>
</style>
