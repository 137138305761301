<template>
  <v-form ref="form" v-model="valid">
    <v-dialog v-model="dialog" width="550" persistent>
      <v-card>
        <v-toolbar color="success" dark flat>
          <span class="headline">Editar usuário vinculado</span>
          <v-spacer />
          <v-btn flat icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container class="pt-2" grid-list-md v-if="employeeData">
          <v-card-text>
            <v-layout column>
              <v-text-field
                v-model="employeeData.name"
                label="Nome"
                :rules="[validationRules.required]"
                :loading="loading"
              />

              <v-text-field
                v-model="employeeData.cpf"
                label="CPF"
                :rules="[validationRules.required, validationRules.cpf]"
                :loading="loading"
                :mask="masks.cpf"
                disabled
              />

              <span class="my-2 font-weight-bold">Alterar Email</span>

              <v-text-field
                v-model="employeeData.email"
                label="E-mail"
                :loading="loading"
                :rules="[validationRules.required, validationRules.email]"
              />

              <span class="grey--text">
                * Envia um aviso para o email antigo e um email de verificação para o novo email
                para autorizar a alteração.
              </span>
            </v-layout>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn flat @click="close">Cancelar</v-btn>
            <v-btn
              color="primary"
              :disabled="!valid"
              @click="save"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import employeeService from '@/services/employee-service';
import validationRules from '@/mixins/validation-rules';
import masks from '@/utils/masks';
import { cloneDeep } from 'lodash';

export default {
  name: 'EditEmployeeDialog',
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      validationRules,
      masks,
      dialog: false,
      valid: false,
      loading: false,
      employeeData: {},
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.cloneEmployee();
      this.dialog = false;
    },
    cloneEmployee() {
      this.employeeData = cloneDeep(this.employee.user);
    },
    save() {
      this.loading = true;

      employeeService
        .updateEmployee(this.employee.user.id, this.employeeData)
        .then(() => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'success',
            message: 'Dados do usuário atualizados com sucesso!',
            messageIcon: 'mdi-check',
          });

          this.$emit('updateList');
          this.close();
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.response.data.join(', '),
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    employee() {
      this.cloneEmployee();
    },
  },
};
</script>

<style>
</style>
