var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { staticClass: "ma-2", attrs: { column: "" } },
    [
      _c("span", { staticClass: "subheading font-weight-bold" }, [
        _vm._v(_vm._s(_vm.quantityOfEmployees)),
      ]),
      _vm._l(_vm.employees, function (employee) {
        return _c(
          "v-card",
          { key: employee.id, staticClass: "mt-3", attrs: { elevation: "2" } },
          [
            _c(
              "v-card-title",
              { staticClass: "px-3 py-2" },
              [
                _c("v-icon", [_vm._v("mdi-account")]),
                _c("span", { staticClass: "ml-1" }, [
                  _vm._v(_vm._s(employee.user.name)),
                ]),
                _c("v-spacer"),
                _vm._l(employee.roles, function (role) {
                  return _c(
                    "v-chip",
                    {
                      key: role,
                      attrs: {
                        color: _vm.chipRoleColor(role),
                        "text-color": "white",
                        disabled: "",
                        label: "",
                        small: "",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.translateRole(role)) +
                          "\n      "
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
            _c(
              "v-card-text",
              { staticClass: "grey lighten-3 py-0" },
              [
                _c(
                  "v-layout",
                  { attrs: { row: "", "align-center": "" } },
                  [
                    _c("span", [
                      _vm._v("CPF: "),
                      _c("strong", [
                        _vm._v(_vm._s(_vm.formatCpfCnpj(employee.user.cpf))),
                      ]),
                    ]),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v("Email: "),
                      _c("strong", [_vm._v(_vm._s(employee.user.email))]),
                    ]),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "text-capitalize",
                        attrs: { flat: "", small: "", color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.editEmployee(employee)
                          },
                        },
                      },
                      [
                        _vm._v("\n          Editar\n          "),
                        _c(
                          "v-icon",
                          { staticClass: "ml-2", attrs: { size: "16" } },
                          [_vm._v("mdi-pencil")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm.selectedEmployee
        ? _c("edit-employee-dialog", {
            ref: "editEmployee",
            attrs: { employee: _vm.selectedEmployee },
            on: { updateList: _vm.getEmployees },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }