<template>
  <v-layout column class="ma-2">
    <span class="subheading font-weight-bold">{{ quantityOfEmployees }}</span>

    <v-card class="mt-3" elevation="2" v-for="employee in employees" :key="employee.id">
      <v-card-title class="px-3 py-2">
        <v-icon>mdi-account</v-icon>
        <span class="ml-1">{{employee.user.name}}</span>

        <v-spacer/>

        <v-chip
          v-for="role in employee.roles"
          :key="role"
          :color="chipRoleColor(role)"
          text-color="white"
          disabled
          label
          small
        >
          {{ translateRole(role) }}
        </v-chip>
      </v-card-title>

      <v-card-text class="grey lighten-3 py-0">
        <v-layout row align-center>
          <span>CPF: <strong>{{formatCpfCnpj(employee.user.cpf)}}</strong></span>
          <span class="ml-3">Email: <strong>{{employee.user.email}}</strong></span>

          <v-spacer />

          <v-btn flat small color="primary" class="text-capitalize" @click="editEmployee(employee)">
            Editar
            <v-icon size="16" class="ml-2">mdi-pencil</v-icon>
          </v-btn>
        </v-layout>
      </v-card-text>
    </v-card>

    <edit-employee-dialog
      v-if="selectedEmployee"
      ref="editEmployee"
      :employee="selectedEmployee"
      @updateList="getEmployees"
    />
  </v-layout>
</template>

<script>
import PersonService from '@/services/person-service';
import formatCpfCnpj from '@/utils/formatCpfCnpj';
import EditEmployeeDialog from '@/components/person/employees/EditEmployeeDialog.vue';

export default {
  name: 'EmployeeList',
  props: {
    companyId: {
      required: true,
    },
  },
  components: {
    EditEmployeeDialog,
  },
  mounted() {
    this.getEmployees();
  },
  data() {
    return {
      formatCpfCnpj,
      employees: [],
      selectedEmployee: {},
    };
  },
  computed: {
    quantityOfEmployees() {
      return this.employees.length !== 1 ? `${this.employees.length} Pessoas vinculadas` : `${this.employees.length} Pessoa vinculada`;
    },
  },
  methods: {
    getEmployees() {
      PersonService
        .getEmployees(this.companyId)
        .then((data) => {
          this.employees = data;
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.message,
            messageIcon: 'mdi-alert-outline',
          });
        });
    },
    editEmployee(employee) {
      this.$refs.editEmployee.open();
      this.selectedEmployee = employee;
    },
    translateRole(role) {
      return {
        admin: 'Administrador',
        company: 'Empresa',
        technical: 'Responsável Técnico',
        receiver: 'Responsável pelo Recebimento',
      }[role];
    },
    chipRoleColor(role) {
      return {
        admin: 'cyan',
        company: 'blue',
        technical: 'deep-purple',
        receiver: 'orange',
      }[role];
    },
  },
};
</script>

<style>
</style>
