var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "in-layout",
    [
      _c(
        "template",
        { slot: "list" },
        [
          _c(
            "v-container",
            { staticClass: "px-4", attrs: { "grid-list-md": "" } },
            [
              _c("v-layout", { staticClass: "ma-2", attrs: { column: "" } }, [
                _c(
                  "div",
                  { staticClass: "mb-4" },
                  [
                    _c(
                      "router-link",
                      {
                        staticStyle: { cursor: "pointer" },
                        attrs: { tag: "span", to: { name: "Empresas" } },
                      },
                      [
                        _c("v-icon", { attrs: { size: "18" } }, [
                          _vm._v("mdi-chevron-left"),
                        ]),
                        _vm._v(
                          "\n            Voltar para empresas cadastradas\n          "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("h1", [_vm._v("Dados da empresa")]),
              ]),
              _c(
                "v-flex",
                { staticClass: "mx-2 my-4", attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pa-3" },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md7: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Nome",
                                          rules: [_vm.validationRules.required],
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.entity.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.entity, "name", $$v)
                                          },
                                          expression: "entity.name",
                                        },
                                      }),
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs6: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "CNPJ ou CPF",
                                                  mask: _vm.getCpfCnpjMask(
                                                    _vm.entity.cpf_cnpj
                                                  ),
                                                  disabled: "",
                                                },
                                                model: {
                                                  value: _vm.entity.cpf_cnpj,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.entity,
                                                      "cpf_cnpj",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "entity.cpf_cnpj",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "ml-3",
                                              attrs: { xs6: "" },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  mask: "(##) # ####-####",
                                                  label: "Celular",
                                                  required: "",
                                                  rules: [
                                                    _vm.validationRules
                                                      .cellphone,
                                                  ],
                                                },
                                                model: {
                                                  value: _vm.entity.cellphone,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.entity,
                                                      "cellphone",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression:
                                                    "entity.cellphone",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-text-field", {
                                        attrs: {
                                          "append-icon": "mdi-magnify",
                                          label: "CEP",
                                          mask: _vm.masks.postalcode,
                                          rules: [
                                            _vm.validationRules.required,
                                            _vm.validationRules.postalcode,
                                          ],
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.searchAddressByCEP.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                          blur: _vm.searchAddressByCEP,
                                        },
                                        model: {
                                          value: _vm.entity.address.zip_code,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.entity.address,
                                              "zip_code",
                                              $$v
                                            )
                                          },
                                          expression: "entity.address.zip_code",
                                        },
                                      }),
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs10: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Logradouro",
                                                  rules: [
                                                    _vm.validationRules
                                                      .required,
                                                  ],
                                                  loading:
                                                    _vm.isFetchingAddress,
                                                  readonly:
                                                    _vm.isFetchingAddress,
                                                },
                                                model: {
                                                  value:
                                                    _vm.entity.address.street,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.entity.address,
                                                      "street",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "entity.address.street",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "ml-3",
                                              attrs: { xs2: "" },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: [
                                                    _vm.validationRules
                                                      .required,
                                                  ],
                                                  label: "Número",
                                                  loading:
                                                    _vm.isFetchingAddress,
                                                  readonly:
                                                    _vm.isFetchingAddress,
                                                },
                                                model: {
                                                  value:
                                                    _vm.entity.address.number,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.entity.address,
                                                      "number",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "entity.address.number",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs3: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Bairro",
                                                  rules: [
                                                    _vm.validationRules
                                                      .required,
                                                  ],
                                                  loading:
                                                    _vm.isFetchingAddress,
                                                  readonly:
                                                    _vm.isFetchingAddress,
                                                },
                                                model: {
                                                  value:
                                                    _vm.entity.address
                                                      .neighborhood,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.entity.address,
                                                      "neighborhood",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "entity.address.neighborhood",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "ml-3",
                                              attrs: { xs9: "" },
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Complemento",
                                                  loading:
                                                    _vm.isFetchingAddress,
                                                  readonly:
                                                    _vm.isFetchingAddress,
                                                },
                                                model: {
                                                  value:
                                                    _vm.entity.address
                                                      .complement,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.entity.address,
                                                      "complement",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "entity.address.complement",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs3: "" } },
                                            [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  items: _vm.federalUnits,
                                                  rules: [
                                                    _vm.validationRules
                                                      .required,
                                                  ],
                                                  loading:
                                                    _vm.isFetchingAddress,
                                                  readonly:
                                                    _vm.isFetchingAddress,
                                                  label: "Estado",
                                                  "no-data-text":
                                                    "Nenhum resultado encontrado",
                                                  "item-text": "acronym",
                                                  "item-value": "id",
                                                  "return-object": "",
                                                  "single-line": "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.fetchCities()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.entity.state,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.entity,
                                                      "state",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "entity.state",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "ml-3",
                                              attrs: { xs9: "" },
                                            },
                                            [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  items: _vm.cities,
                                                  rules: [
                                                    _vm.validationRules
                                                      .required,
                                                  ],
                                                  loading:
                                                    _vm.isFetchingAddress,
                                                  readonly:
                                                    _vm.isFetchingAddress,
                                                  label: "Município",
                                                  "no-data-text":
                                                    "Nenhum resultado encontrado",
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  "return-object": "",
                                                  "single-line": "",
                                                },
                                                model: {
                                                  value: _vm.entity.city,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.entity,
                                                      "city",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "entity.city",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-flex",
                                    { attrs: { md4: "" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "subheading font-weight-bold",
                                        },
                                        [_vm._v("Perfil:")]
                                      ),
                                      _c(
                                        "v-layout",
                                        { attrs: { column: "" } },
                                        [
                                          _c("v-checkbox", {
                                            attrs: {
                                              color: "primary",
                                              label: "Gerador",
                                              value: "generator",
                                            },
                                            model: {
                                              value: _vm.entity.profile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.entity,
                                                  "profile",
                                                  $$v
                                                )
                                              },
                                              expression: "entity.profile",
                                            },
                                          }),
                                          _c("v-checkbox", {
                                            attrs: {
                                              color: "primary",
                                              label: "Destinador",
                                              value: "receiver",
                                            },
                                            model: {
                                              value: _vm.entity.profile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.entity,
                                                  "profile",
                                                  $$v
                                                )
                                              },
                                              expression: "entity.profile",
                                            },
                                          }),
                                          _c("v-checkbox", {
                                            attrs: {
                                              color: "primary",
                                              label: "Transportador",
                                              value: "transporter",
                                            },
                                            model: {
                                              value: _vm.entity.profile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.entity,
                                                  "profile",
                                                  $$v
                                                )
                                              },
                                              expression: "entity.profile",
                                            },
                                          }),
                                          _c("v-checkbox", {
                                            attrs: {
                                              color: "primary",
                                              label: "Armazenador Temporário",
                                              value: "temporary_storage",
                                            },
                                            model: {
                                              value: _vm.entity.profile,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.entity,
                                                  "profile",
                                                  $$v
                                                )
                                              },
                                              expression: "entity.profile",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "pa-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", disabled: !_vm.valid },
                              on: { click: _vm.save },
                            },
                            [_vm._v("Salvar alterações")]
                          ),
                          _c(
                            "v-btn",
                            { attrs: { flat: "" }, on: { click: _vm.cancel } },
                            [_vm._v("Cancelar")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c("employee-list", { attrs: { companyId: _vm.id } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }